import { supabase } from "./supabase";

const signInWithApple = async () => {
  const { error } = await supabase.auth.signInWithOAuth({
    provider: "apple",
    options: {
      redirectTo: 'https://uoslink.com',
    },
  });
  if (error) {
    throw error.message;
  }
};

export const useUser = () => {
  return {
    signInWithApple,
  }; 
};